@import "./colors.scss";
@import "./variables.scss";
@import "./mixins.scss";

//--------------------------- COMMON ------------------------------

.ant-collapse,
.ant-radio-group,
.ant-radio-wrapper,
.ant-radio,
.ant-input,
.ant-notification,
.ant-message,
.ant-table {
  font-family: $font-family-primary !important;
}

.ant-notification,
.ant-message {
  z-index: 99999;
}

.ant-select-dropdown {
  z-index: 1100 !important;
}
.ant-tooltip-inner {
  margin: 8px;
}

.ant-radio-button-wrapper {
  border: 0.5px solid $border !important;
  border-radius: $border-radius-base !important;
  margin-right: 5px !important;

  &::before {
    display: none !important;
  }
}

//--------------------------- REACT-QUILL-EDITOR ------------------------------

.oriEditorWrapper {
  .ql-container {
    font-size: 14px !important;
    border: none !important;

    .ql-editor {
      padding: 0 !important;
      max-height: 125px;
    }

    .ql-blank {
      &::before {
        left: 0 !important;
        right: 0;
        color: $font-color-light !important;
      }
    }
  }

  .ql-toolbar {
    border: none !important;
    padding: 0px !important;

    button {
      width: 24px !important;
      padding: 3px !important;
    }
  }

  .ql-snow {
    a {
      color: $primary !important;
    }

    .ql-stroke {
      stroke: $font-color-light !important;
    }

    .ql-fill {
      fill: $font-color-light !important;
    }

    .ql-picker-options {
      margin-bottom: 5px;
      bottom: 100%;
      top: unset !important;
    }
  }

  .ql-active {
    background-color: $default !important;
    font-weight: bold !important;
  }
}

//--------------------------- CHATBOT-MESSAGE-TYPES LIBRARY ------------------------------

.ori-mt-carouselWithButtonsContainer {
  .ori-mt-CarouselContainer {
    .slick-arrow {
      background: none;
      font-size: 10px;
      color: $font-color-light;
      width: auto;
      top: unset;
      bottom: -28px;
      z-index: 1;
    }

    .slick-next {
      right: 10px;

      &:hover,
      &:focus {
        color: $font-color;
      }

      &::before {
        content: "";
      }
    }

    .slick-prev {
      left: 10px;

      &:hover,
      &:focus {
        color: $font-color;
      }

      &::before {
        content: "";
      }
    }

    .slick-dots {
      bottom: -10px;

      li,
      .slick-active {
        button {
          background-color: $font-color-light;
        }
      }
    }
  }
}

.ori-upload-full-width {
  .ant-upload {
    width: 100%;
  }
}

//-------------------------- BADGE ----------------------------

.ori-badge-dot-green {
  .ant-badge-dot {
    background-color: $green;
  }
}

.ori-badge-dot-default {
  .ant-badge-dot {
    background-color: $font-color;
  }
}

//--------------------------- MODAL ------------------------------

.ori-modal-primary {
  .ant-modal-close {
    color: $white;

    &:hover {
      color: $font-color-light;
    }
  }

  .ant-modal-header {
    background-color: $primary;

    .ant-modal-title {
      color: $white;
    }
  }

  .ant-modal-body {
    padding: 0px !important;
  }
}

@media only screen and (min-width: 769px) {
  .ori-modal-lg {
    width: 960px !important;
    max-width: 95vw;
  }
}

//--------------------------- SELECT -------------------------------

.ori-select-bg-green {
  .ant-select-selector {
    color: $white;
    background-color: $green !important;
    border-color: $green !important;
  }

  .ant-select-arrow {
    color: $white;
  }
}

.ori-select-bg-danger {
  .ant-select-selector {
    color: $white;
    background-color: $danger !important;
    border-color: $danger !important;
  }

  .ant-select-arrow {
    color: $white;
  }
}

//--------------------------- TOOLTIP ------------------------------

.entityTooltip,
.intentTooltip {
  .ant-tooltip-inner {
    padding: 1px 4px;
    min-height: 14px;
    font-size: 11px;
  }
}

.ori-tooltip-arrow-hidden {
  .ant-tooltip-arrow {
    display: none;
  }
}

.ori-tooltip-bg-white {
  .ant-tooltip-inner {
    background-color: $white;
    color: $font-color;
  }
}

.ori-tooltip-tag {
  .ant-tooltip-inner {
    min-height: 20px;
    padding: 3px 8px;
  }
}

//--------------------------- TABLE -----------------------------

.ori-table-content-align-center {
  th,
  td {
    text-align: center !important;
  }
}

.ori-nested-expanded-table {
  .ant-table {
    margin: 0 !important;
  }
}

.ori-card-row-table {
  .ant-table {
    background-color: transparent;

    table {
      -webkit-border-vertical-spacing: 5px;
    }

    // th, td {
    //     color: $font-color;
    //     white-space: pre-wrap;
    //     border: none;
    //     min-width: 80px;
    //     max-width: 300px !important;
    //     word-break: break-word;
    // }

    thead {
      tr {
        th {
          background-color: transparent;
        }
      }
    }
  }
}

.ori-table-default {
  .ant-table {
    background-color: transparent;

    table {
      border-collapse: collapse;
    }

    th,
    td {
      color: $font-color;
      white-space: pre-wrap;
      min-width: 80px;
      max-width: 300px !important;
      word-break: break-word;
    }

    thead {
      tr {
        th {
          background-color: transparent;
        }
      }
    }
  }
}

.ori-table-header-display-none {
  th {
    display: none;
  }
}

//--------------------------- POPOVER ------------------------

.ori-popover-pad-5 {
  .ant-popover-inner-content {
    padding: 5px;
  }
}

//--------------------------- POPCONFIRM ------------------------

.ori-popover-message-no-padding {
  .ant-popover-message-title {
    padding: 0 !important;
  }
}

//--------------------------- DRAWER -----------------------------

.ori-drawer {
  color: $font-color !important;

  .ant-drawer-content-wrapper {
    box-shadow: 0 2px 4px 2px rgba($black, 0.1);

    .ant-drawer-header {
      padding-top: 18px;
      padding-bottom: 19px;

      .ant-drawer-title {
        color: $font-color;
      }
    }
  }
}

// .ori-drawer-title-single-line {
//     .ant-drawer-title {
//         text-overflow: ellipsis;
//         overflow: hidden;
//         white-space: nowrap;
//         padding-right: 15px;
//     }
// }

// .ori-drawer-header-no-pad {
//     .ant-drawer-header {
//         padding: 0 !important;
//     }
// }

// .ori-drawer-body-with-header {
//     .ant-drawer-body {
//         height: calc( 100% - 62px);
//         overflow: hidden;
//     }
// }

.ori-drawer-body-no-pad {
  .ant-drawer-body {
    padding: 0px;
  }
}

.ori-drawer-height-without-footer {
  .ant-drawer-body {
    height: calc(100% - 60px);
  }
}

.ori-drawer-footer-pad {
  .ant-drawer-body {
    padding: 0px 0px 65px 0px;
  }
}

.ori-drawer-bg-default {
  .ant-drawer-body {
    background: $default;
  }
}

//--------------------------- INPUT -----------------------------

.ori-input-none {
  border: none;
  box-shadow: none;
  background: transparent;

  &:focus,
  &:hover,
  &:active {
    border: none;
    box-shadow: none;
    outline: none;
    background: transparent;
  }
}

//--------------------------- COLLAPSE -----------------------------

.ori-collapse-card {
  background: none;
  border: none;

  .ant-collapse-item {
    margin-top: 5px;
    margin-bottom: 5px;
    border: none;

    .ant-collapse-header {
      border: 1px solid $border;
    }

    .ant-collapse-content {
      border-top: none;
      border-bottom: 1px solid $border;
      border-left: 1px solid $border;
      border-right: 1px solid $border;

      .ant-collapse-content-box {
        padding: 10px 15px;
      }
    }

    &:last-child {
      .ant-collapse-header {
        border-radius: 0px;
      }
    }
  }
}

.ori-collapse-card-shadow-light {
  .ant-collapse-item {
    box-shadow: 0 2px 4px 0 rgba($black, 0.06);
  }
}

.ori-collapse-card-shadow-none {
  .ant-collapse-item {
    box-shadow: none !important;
  }
}

.ori-collapse-card-white {
  .ant-collapse-header {
    background: $white;
  }
}

.ori-collapse-card-default {
  .ant-collapse-header {
    background: $default;
  }
}

//--------------------------- JSON SCHEMA FORM ------------------------------

.ori-json-schema-form-container {
  color: $font-color;

  .form-group {
    margin-bottom: 8px;

    .form-control {
      border-color: $border;

      //height: 32px;
      font-size: $font-size-xs;
      padding: 4px 11px;
      box-shadow: none;

      &:focus {
        border-color: $primary;
        outline: 0;
        box-shadow: 0 0 0 2px rgba(90, 81, 222, 0.2);
      }
    }

    legend {
      font-size: $font-size-md;

      &::first-letter {
        text-transform: capitalize;
      }
    }

    .control-label {
      font-weight: $font-bold;

      &::first-letter {
        text-transform: capitalize;
      }
    }

    .array-item-add {
      .btn-add {
        background-color: $primary;
        border-color: $primary;

        &:focus {
          outline: none;
        }
      }
    }
  }
}

//--------------------------- REACT-FLOW-DIAGRAM------------------------------

//react-flow-diagram has used styled-component for handling classname.
// this classes are generated by third party and can change if library will update.
//classNames will change when they rebuild the react-flow-diagram.
//so you might get ui changes after npm install..

.liuumT {
  //root container of library
  background-color: $default;

  textPath {
    font-size: 13px !important;
    text-transform: capitalize;
    fill: $font-color;
  }

  .xjtvc {
    // canvas root container
    background-color: $default;
    background-image: linear-gradient(
        0deg,
        transparent 0%,
        transparent 96%,
        $white 100%
      ),
      linear-gradient(90deg, transparent 0%, transparent 96%, $white 100%) !important;

    .kGbQqt {
      // arrow
      stroke: $font-color-light;
    }

    .egtcJs {
      // card panel list
      display: none;
    }
  }

  .sc-ifAKCX {
    // main panel container
    ul {
      display: flex;
      flex-direction: row-reverse;
      background-color: $white;
      border-bottom-right-radius: 5px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);

      li {
        width: 35px;
        height: 35px;
        padding: 8px;
        background-color: transparent;
        border: none;

        path {
          fill: $font-color-light;
        }

        &:hover {
          background-color: $default;
        }
      }
    }
  }
}

.ori-fixed-bottom {
  position: fixed !important;
  width: 525px !important;
  bottom: 0px !important;
  z-index: 1 !important;
}

//--------------------------- STEPS -----------------------------
.ant-steps-item-description {
  padding-bottom: 0 !important;
}
